<template>
    <div class="row align-middle" style="
        font-size: 2em;
        line-height: 1em;">
        <i class="feather icon-grid ml-auto"></i>
        <span class="mr-auto pl-1" style="font-size: 0.5em;" v-text="message"></span>
    </div>
</template>
<script>
export default {
    props : [
        "message"
    ],
}
</script>
<style scoped>

</style>